import { isMedication, Medication, MedicationKnowledge, MedicationRequest } from "fhir"
import { useId, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { faPrescription } from "@fortawesome/pro-regular-svg-icons"
import InfiniteScroll from "react-infinite-scroller"

import {
  EmptyMessage,
  SkeletonLoader,
  Slideover,
  StackedListContainer,
  useChargeItemDefinitions,
  useWindowBounds,
} from "commons"
import { useLoginContext } from "security"
import {
  completeMR,
  MedicationKnowledgeDetails,
  MedicationRequestData,
  MedicationRequestDetails,
  stopMR,
  useDeleteMedicationRequest,
  useMedicationRequestDataBind,
  useMedicationRequests,
  useStopMedicationRequest,
} from "commons/meds"
import { BILLING_TYPES_CODES } from "data"
import { getBillingTypeCode, getMedCodes, getPriceByCode } from "utils"

import { MedicationRequestInfo } from "../types"
import { useCompleteMrOrder } from "../hooks"
import { prescriptionItemModel } from "./prescriptionItemModel"
import { discardMR } from "../helpers"
import { MedicationDetails } from "./MedicationDetails"

const PrescriptionList = ({ searchFilter, statusFilter }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { isSmallScreen } = useWindowBounds()
  const { managingOrganizationId, loggedInPatientId: patientId } = useLoginContext()
  const {
    medicationRequests,
    medicationKnowledges,
    medicationDispenses,
    isLoading,
    hasNextPage,
    fetchNextPage,
    reloadMedications,
  } = useMedicationRequests(patientId as string, "medication", statusFilter ?? [], 100, 1, searchFilter)

  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const [selectedMed, setSelectedMed] = useState<Medication>()

  const { billToPatientMrs } = medicationRequests?.reduce(
    (acc, mr) => {
      const billingTypeCode = getBillingTypeCode(mr)

      return billingTypeCode === undefined
        ? {
            billToPatientMrs: [...acc.billToPatientMrs, mr],
          }
        : billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
          ? {
              billToPatientMrs: [...acc.billToPatientMrs, mr],
            }
          : {
              billToPatientMrs: acc.billToPatientMrs,
            }
    },
    { billToPatientMrs: [] as MedicationRequest[] },
  ) ?? { billToPatientMrs: [] }

  const billToPatientMedicationKnowledgeCodes = billToPatientMrs
    ? getMedCodes({ meds: billToPatientMrs, withQty: true })
    : undefined

  const { chargeItemDefinitions: cids } = useChargeItemDefinitions(managingOrganizationId, {
    billToPatientCIDs: billToPatientMedicationKnowledgeCodes,
  })

  const { medicationRequestData: mrDataWithoutPrices } = useMedicationRequestDataBind(
    medicationRequests,
    medicationKnowledges,
    cids.billToPatientCIDs,
    medicationDispenses,
  )

  const { deleteMedicationRequest } = useDeleteMedicationRequest()
  const { stopMedicationRequest } = useStopMedicationRequest({ refreshMedications: reloadMedications })
  const { completeMedicationRequest } = useCompleteMrOrder()

  const medicationRequestData = mrDataWithoutPrices.map((item) => {
    const billingTypeCode = getBillingTypeCode(item?.medicationRequestInfo)
    const patientPrice = getPriceByCode({
      chargeItemDefinitions: cids?.billToPatientCIDs ?? {},
      medCoding: item.medicationKnowledge?.code?.coding,
      factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
      includePatientFee: true,
    })
    const practicePrice = getPriceByCode({
      chargeItemDefinitions: cids?.billToPracticeOrInsuranceCIDs ?? {},
      medCoding: item.medicationKnowledge?.code?.coding,
      factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
    })

    const priceInfo =
      billingTypeCode === undefined
        ? { patientPrice, practicePrice }
        : billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
          ? { patientPrice }
          : { practicePrice }

    return {
      ...item,
      ...priceInfo,
    }
  })

  const selectedMedicationRequestData = useMemo(() => {
    const mrId = searchParams.get("mrId")
    return medicationRequestData.find(({ medicationRequestInfo }) => medicationRequestInfo.id === mrId)
  }, [searchParams, medicationRequestData])

  const discard = (mrId: string) => {
    discardMR(mrId, () => {
      deleteMedicationRequest(mrId)
    })
  }
  const stop = (mrId: string) => {
    stopMR(mrId, () => {
      stopMedicationRequest([mrId])
    })
  }
  const complete = (mrId: string) => {
    completeMR(mrId, () => {
      completeMedicationRequest(mrId)
    })
  }

  const previewMedOrMK = (mrData: MedicationRequestData) => {
    if (mrData.medicationRequestInfo.contained?.[0] && isMedication(mrData.medicationRequestInfo.contained[0])) {
      setSelectedMed(mrData.medicationRequestInfo.contained[0])
      return
    }

    setSelectedMK({ mk: mrData.medicationKnowledge, mr: mrData.medicationRequestInfo })
  }

  const showMRDetails = (mr: MedicationRequestInfo) => {
    if (mr.id) {
      searchParams.set("mrId", mr.id)
      setSearchParams(searchParams)
    }
  }

  const hideMRDetails = () => {
    searchParams.delete("mrId")
    setSearchParams(searchParams)
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) {
    return loader()
  }

  if (medicationRequestData.length === 0) {
    return <EmptyMessage icon={faPrescription} itemTitle="Prescription" />
  }

  return (
    <>
      <div className="h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={medicationRequestData}
            keyGenerator={({ medicationRequestInfo }) => medicationRequestInfo.id}
            itemModelBuilder={(item) =>
              prescriptionItemModel({
                mrData: item,
                discard,
                stop,
                complete,
                preview: previewMedOrMK,
                showDetails: showMRDetails,
              })
            }
          />
        </InfiniteScroll>
      </div>

      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        onHide={() => setSelectedMK(undefined)}
        showImgFallback={false}
        mr={selectedMK?.mr}
      />
      <MedicationDetails selectedMed={selectedMed} setSelectedMed={setSelectedMed} />
      <Slideover
        showSlide={!!selectedMedicationRequestData}
        onHide={hideMRDetails}
        title="Medication Request Details"
        showCancel
        cancelLabel="Close"
        dismissable
        showCloseIcon
        position={isSmallScreen ? "bottom" : "right"}
      >
        {selectedMedicationRequestData && (
          <MedicationRequestDetails
            medicationRequest={selectedMedicationRequestData.medicationRequestInfo}
            medicationDispense={selectedMedicationRequestData.medicationDispense}
          />
        )}
      </Slideover>
    </>
  )
}

type Props = { statusFilter?: string[]; searchFilter?: string }

export { PrescriptionList }
