import { Message } from "primereact/message"
import { FC } from "react"

const PrescriptionWarningMessage: FC<Props> = ({ message, actionText, onClic }) => {
  return (
    <div className="sticky top-0 z-20 bg-white py-2 ">
      <Message
        className="w-full "
        severity="warn"
        content={
          <div className="flex gap-2 text-sm flex flex-col md:flex-row">
            <span>{message}</span>
            <button className="font-medium underline" onClick={onClic}>
              {actionText}
            </button>
          </div>
        }
      />
    </div>
  )
}

type Props = {
  message: string
  actionText: string
  onClic(): void
}

export { PrescriptionWarningMessage }
